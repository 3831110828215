import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, Seo } from 'components';
import Header from 'components/Header';
import EmailForm from './components/EmailForm';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { createLead, updateQuizAnswers, updateUser } from 'state/user/effects';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import Text from 'components/Text';
import {
  useFirstVisitDate,
  usePageView,
  useQuizData,
  useTrackingQueryParams,
} from 'utils/hooks';
import { Events } from 'utils/events';
import BlackFridayBanner from '../../../components/BlackFridayBanner';
import Tracking from 'utils/tracking';
import { cliendCodeToUrl } from 'utils/cliendCodeToUrl';
import { normalizeStates } from 'utils/localization';
import { Quiz, QuizKey } from 'types/quiz';

const Container = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 28.75rem;
  width: 100%;
  flex-direction: column;
  padding: 3rem 1rem;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 1.5rem;
  span {
    color: #3fa134;
  }
`;

const Subtitle = styled(Text)`
  @media ${mobile} {
    text-align: center;
  }
`;

const Footer = styled(Text)`
  color: #777;
  text-align: center;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 1rem;
  @media ${tablet} {
    padding-top: 1.5rem;
  }
`;

const ImageContainer = styled.div`
  margin: 4rem 0rem;
  width: 16rem;
  height: 16rem;

  @media ${mobile} {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const EmailFormStyled = styled(EmailForm)``;

const Email: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const trackingParams = useTrackingQueryParams();
  const { isTablet } = useQuery();
  const { quiz_answers, user, geolocation, code, user_ip } = useSelector(
    (state: AppState) => state.user,
  );
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { countdownDiffInMS } = useFirstVisitDate();

  const quiz =
    new URLSearchParams(location.search).get(QuizKey.Qz) ?? Quiz.Main;

  const dispatch = useDispatch();

  const { goToCheckout, goToResults } = useRouter();

  usePageView({
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : 'f',
  });

  const handleNextRoute = () => {
    goToCheckout({ discount: false });
  };

  const handleGetResults = async (email: string) => {
    setError('');

    setLoading(true);

    Tracking.trackCTAButton(location.pathname);

    if (!user?.email || email !== user.email) {
      dispatch(dispatch(updateUser({ email, password: '' })));
    }

    quiz_answers.quiz = quiz;

    try {
      const code =
        (await dispatch(
          createLead({
            email,
            funnel: quiz,
            quiz_answers: { ...quiz_answers, ...trackingParams },
          }),
        )) ?? '';

      // cliendCodeToUrl(code);
      dispatch(updateQuizAnswers(trackingParams));

      const userState = normalizeStates(
        geolocation?.iso_country || '',
        geolocation?.iso_state || '',
      );

      Tracking.leadCreated(
        code,
        email,
        geolocation?.iso_country,
        quiz_answers?.gender ? quiz_answers?.gender[0] : 'f',
        userState,
        user_ip,
      );
      Events.checkoutPageVisit(code);

      handleNextRoute();
    } catch (error) {
      setError(error?.response.data?.errors?.email ?? '');

      setLoading(false);
    }
  };

  const data = useQuizData('email');

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Container>
        <Seo />
        <Header logoVariant="center" hasDivider={true} />
        <ContentContainer>
          <Title dangerouslySetInnerHTML={{ __html: data?.title }} />
          <EmailFormStyled
            loading={loading}
            error={error}
            onSubmit={handleGetResults}
            continueBtnTitle={data?.continueBtnTitle}
            placeholder={data?.inputPlaceholder}
            label={data?.inputLabel}
          />
          <Footer>{data?.footer}</Footer>
        </ContentContainer>
      </Container>
    </>
  );
};

export default Email;
