import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import styled from 'styled-components';
import emailMisspelled, { top100 } from 'email-misspelled';

import { mobile, tablet } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import { Text } from 'components';

interface EmailFormProps {
  loading: boolean;
  error: string;
  placeholder: string;
  continueBtnTitle: string;
  onSubmit: (email: string) => void;
  showEmailSuggestion?: boolean;
  label: string;
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const Submit = styled(PrimaryButton)`
  width: 100%;
  margin-top: 1.5rem;
`;

const InputStyled = styled.input`
  display: flex;
  height: 3.5rem;
  padding: 0.80031rem 0.91469rem;
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 0.75rem;
  background: #f0f0f0;
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  ::placeholder {
    color: #777;
  }

  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #f0f0f0 inset;
    box-shadow: 0 0 0 1000px #f0f0f0 inset;
  }

  @media ${tablet} {
    height: 3rem;
  }
`;

const Error = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  height: 1rem;
  color: red;
  margin: 0.5rem 0;
`;

const SuggestionDropdownContainer = styled.div`
  border-radius: 0.5rem;
  background: rgba(224, 224, 224, 0.26);
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const Label = styled(Text)`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-bottom: 0.5rem;
`;

const SuggestedEmail = styled(Text)`
  color: #555770;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.021rem;
  cursor: pointer;
`;

const EmailForm: FC<EmailFormProps> = ({
  loading,
  error,
  placeholder = 'Please enter your email',
  continueBtnTitle = 'Continue',
  onSubmit,
  showEmailSuggestion = false,
  label,
  ...props
}) => {
  const [email, setEmail] = useState<string>('');
  const emailChecker = emailMisspelled({ domains: top100 });
  const suggestions = emailChecker(email);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(email);
  };

  const suggestionIsVisible = showEmailSuggestion && !!suggestions?.length;

  return (
    <Container onSubmit={handleSubmit} {...props}>
      <ContentContainer>
        <Label>{label}</Label>
        <InputStyled
          name="email"
          type="email"
          placeholder={placeholder}
          disabled={loading}
          required
          onChange={handleChange}
          value={email}
        />
        {error && <Error>{error}</Error>}
      </ContentContainer>
      <Submit
        disabled={loading}
        loading={loading}
        type="submit"
        cornerVariant="round"
      >
        {continueBtnTitle}
      </Submit>
    </Container>
  );
};

export default EmailForm;
